import React, {useState, useEffect} from 'react'
import SEO from "../components/SEO";
import Layout from "../components/layout";
import {Button} from "../elements";

const APERTURE_VALUES = ['1.4', '5.6', '11', '16'];
const ISO_VALUES = ['100', '400', '800', '2000', '6400'];
const SHUTTERSPEED_VALUES = ['1/8000', '1/6400', '1/5000', '1/4000', '1/3200', '1/2500', '1/2000', '1/1600', '1/1250',
    '1/1000', '1/800', '1/640', '1/500', '1/400', '1/250', '1/200', '1/160', '1/125', '1/100', '1/80', '1/60', '1/50',
    '1/40', '1/30', '1/25', '1/20', '1/15', '1/13', '1/10', '1/8', '1/6', '1/5', '0.4', '0.5', '0.6', '0.8', '1.0', '1.3',
    '1.6', '2.0', '2.5', '3.0', '4.0', '5.0', '6.0', '8.0', '10.0', '15.0', '20.0', '25.0', '30.0'];

const ExposureTriangle = () => {
    const [iso, setIso] = useState('100')
    const [shutterSpeed, setShutterSpeed] = useState('1/8000')
    const [aperture, setAperture] = useState('1.4')
    const DEFAULT_DARK_IMAGE = '/exposure-triangle/1_8000-5.6-100.jpg'
    const DEFAULT_LIGHT_IMAGE = '/exposure-triangle/1_8-5.6-6400.jpg'
    const DEFAULT_IMAGE = `/exposure-triangle/${shutterSpeed.replace("/", "_")}-${aperture}-${iso}.jpg`
    const [imgSource, setImageSource] = useState(DEFAULT_IMAGE);

    useEffect(
        () => {
            return setImageSource(DEFAULT_IMAGE)
        }, [iso, aperture, shutterSpeed]);

    return (
        <Layout>
            <SEO/>
            <div style={{textAlign: 'center', padding: 20}}>
                <h1>Exposure Triangle Live example</h1>
                <p>For me, one of the best ways to learn is by visually seeing it. I built this to compliment the medium
                    article I wrote here. I suggest you read that first before playing around with this. I figured this
                    would be helpful for new shooters in understanding what the exposure triangle does and how you can
                    manipulate it. This
                    was taken on a very overcast day around 4PM. Check back as I'll be adding in more examples from
                    time to time.</p>
                <h2>Camera settings</h2>
                <br/>
                {
                    typeof window !== `undefined` && window.innerWidth <= 600 ? (
                        <div style={{display: 'grid', gridTemplateColumns: 'auto', marginBottom: 60}}>
                            <div>
                                <select value={aperture}
                                        onChange={(e) => {
                                            setAperture(e.target.value)
                                        }}
                                        style={{width: "80%", textAlign: "center", marginBottom: 10}}
                                >
                                    {APERTURE_VALUES.map((apertureValue) => (
                                        <option value={apertureValue} key={apertureValue}
                                                style={{width: "80%", textAlign: "center", marginBottom: 10}}>
                                            f{apertureValue}
                                        </option>
                                    ))}
                                </select>
                                <br/>
                                <Button width="80%" style={{marginBottom:20}}>Set Aperture</Button>
                            </div>
                            <div>
                                <select value={shutterSpeed}
                                        onChange={(e) => {
                                            setShutterSpeed(e.target.value)
                                        }}
                                        style={{width: "80%", textAlign: "center", marginBottom: 10}}
                                >
                                    {SHUTTERSPEED_VALUES.map((shutterSpeedValue) => (
                                        <option value={shutterSpeedValue} key={shutterSpeedValue}>
                                            {shutterSpeedValue}"
                                        </option>
                                    ))}
                                </select>
                                <br/>
                                <Button width="80%" style={{marginBottom:20}}>Set Shutter speed</Button>
                            </div>
                            <div>
                                <select value={iso} onChange={(e) => {
                                    setIso(e.target.value)
                                }}
                                        style={{width: "80%", textAlign: "center", marginBottom: 10}}
                                >
                                    {ISO_VALUES.map((isoValue) => (
                                        <option value={isoValue} key={isoValue}>
                                            {isoValue}
                                        </option>
                                    ))}
                                </select>
                                <br/>
                                <Button width="80%" style={{marginBottom:20}}>Set ISO</Button>
                            </div>
                        </div>
                    ) : (
                        <div style={{display: 'grid', gridTemplateColumns: 'auto auto auto', marginBottom: 60}}>
                            <div>
                                <select value={aperture}
                                        onChange={(e) => {
                                            setAperture(e.target.value)
                                        }}
                                        style={{width: "80%", textAlign: "center", marginBottom: 20}}
                                >
                                    {APERTURE_VALUES.map((apertureValue) => (
                                        <option value={apertureValue} key={apertureValue}
                                                style={{width: "80%", textAlign: "center", marginBottom: 20}}>
                                            f{apertureValue}
                                        </option>
                                    ))}
                                </select>
                                <br/>
                                <Button width="80%">Set Aperture</Button>
                            </div>
                            <div>
                                <select value={shutterSpeed}
                                        onChange={(e) => {
                                            setShutterSpeed(e.target.value)
                                        }}
                                        style={{width: "80%", textAlign: "center", marginBottom: 20}}
                                >
                                    {SHUTTERSPEED_VALUES.map((shutterSpeedValue) => (
                                        <option value={shutterSpeedValue} key={shutterSpeedValue}>
                                            {shutterSpeedValue}"
                                        </option>
                                    ))}
                                </select>
                                <br/>
                                <Button width="80%">Set Shutter speed</Button>
                            </div>
                            <div>
                                <select value={iso} onChange={(e) => {
                                    setIso(e.target.value)
                                }}
                                        style={{width: "80%", textAlign: "center", marginBottom: 20}}
                                >
                                    {ISO_VALUES.map((isoValue) => (
                                        <option value={isoValue} key={isoValue}>
                                            {isoValue}
                                        </option>
                                    ))}
                                </select>
                                <br/>
                                <Button width="80%">Set ISO</Button>
                            </div>
                        </div>
                    )
                }


                <div style={{width: '80%', margin: '0 auto'}}>
                    <img src={imgSource}
                         alt="image to display exposure triangle"
                         onError={() => {
                             if (shutterSpeed.length >= 5) {
                                 setImageSource(DEFAULT_DARK_IMAGE)
                             } else {
                                 setImageSource(DEFAULT_LIGHT_IMAGE)
                             }
                         }}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default ExposureTriangle
